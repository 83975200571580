// Generated by Framer (575e68f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["orjmMQFzs", "r7OHH0o5k", "nL_fG7MoH"];

const serializationHash = "framer-4T1LY"

const variantClassNames = {nL_fG7MoH: "framer-v-14h9v0d", orjmMQFzs: "framer-v-1f2gz8i", r7OHH0o5k: "framer-v-8h6ti2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "orjmMQFzs", Dark: "nL_fG7MoH", Open: "r7OHH0o5k"}

const getProps = ({height, hover, id, title, width, ...props}) => { return {...props, DApvjrdfG: title ?? props.DApvjrdfG ?? "Product", PWSlWeqS6: hover ?? props.PWSlWeqS6, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "orjmMQFzs"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PWSlWeqS6, DApvjrdfG, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "orjmMQFzs", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter170i0h9 = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (PWSlWeqS6) {const res = await PWSlWeqS6(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1f2gz8i", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"orjmMQFzs"} onMouseEnter={onMouseEnter170i0h9} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({nL_fG7MoH: {"data-framer-name": "Dark"}, r7OHH0o5k: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO09idmlvdXNseSBOYXJyb3c=", "--framer-font-family": "\"Obviously Narrow\", \"Obviously Narrow Placeholder\", sans-serif", "--framer-font-size": "13px", "--framer-letter-spacing": "1px", "--framer-line-height": "100%", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))", "--framer-text-transform": "uppercase"}}>Product</motion.p></React.Fragment>} className={"framer-sgopbu"} fonts={["CUSTOM;Obviously Narrow"]} layoutDependency={layoutDependency} layoutId={"y4Iqm5oZz"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px", opacity: 1}} text={DApvjrdfG} variants={{r7OHH0o5k: {opacity: 0.5}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({nL_fG7MoH: {children: <React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO09idmlvdXNseSBOYXJyb3c=", "--framer-font-family": "\"Obviously Narrow\", \"Obviously Narrow Placeholder\", sans-serif", "--framer-font-size": "13px", "--framer-letter-spacing": "1px", "--framer-line-height": "100%", "--framer-text-alignment": "left", "--framer-text-transform": "uppercase"}}>Product</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4T1LY.framer-rwcami, .framer-4T1LY .framer-rwcami { display: block; }", ".framer-4T1LY.framer-1f2gz8i { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 0px 5px 0px; position: relative; width: min-content; }", ".framer-4T1LY .framer-sgopbu { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-4T1LY.framer-1f2gz8i { gap: 0px; } .framer-4T1LY.framer-1f2gz8i > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-4T1LY.framer-1f2gz8i > :first-child { margin-left: 0px; } .framer-4T1LY.framer-1f2gz8i > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 23
 * @framerIntrinsicWidth 53.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"r7OHH0o5k":{"layout":["auto","auto"]},"nL_fG7MoH":{"layout":["auto","auto"]}}}
 * @framerVariables {"PWSlWeqS6":"hover","DApvjrdfG":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerAHYGpxYge: React.ComponentType<Props> = withCSS(Component, css, "framer-4T1LY") as typeof Component;
export default FramerAHYGpxYge;

FramerAHYGpxYge.displayName = "Trigger";

FramerAHYGpxYge.defaultProps = {height: 23, width: 53.5};

addPropertyControls(FramerAHYGpxYge, {variant: {options: ["orjmMQFzs", "r7OHH0o5k", "nL_fG7MoH"], optionTitles: ["Closed", "Open", "Dark"], title: "Variant", type: ControlType.Enum}, PWSlWeqS6: {title: "Hover", type: ControlType.EventHandler}, DApvjrdfG: {defaultValue: "Product", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerAHYGpxYge, [{explicitInter: true, fonts: [{family: "Obviously Narrow", source: "custom", url: "https://framerusercontent.com/assets/5Sg8JFaVJzm9p47dPspwPG3BBY.woff2"}]}], {supportsExplicitInterCodegen: true})